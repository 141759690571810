<template>
    <div class="page-content w-100">
        <div class="row g-0">
      <div class="col-sm-12">
        <p class="text-center page-heading">Home</p>
      </div>
    </div>
        <div class="messageBox">
            <div class="row">
                <!-- <div class="row d-none d-lg-block ">
                    <div class="col-md-12 col-12">
                        <div class="border-color p-4" style="border-radius: 20px;background-color: #ffff;">
                          Publish my wisdom
                        </div>
                    </div>
                </div> -->
                <!-- <div class="d-block d-lg-none">
                    <div class="btn-floating-add">
                        <router-link :to="'/' + role + '/newpost'" class="nav-link text-color">
                            <font-awesome-icon :icon='["fas", "plus-circle"]' />
                        </router-link>
                    </div>
                </div> -->

                <!-- doctor after giving the consultation this message is shown to that spefic user and family -->
                <div v-for="  user in fullUserFamilyConsultationRecord" :key="user">
                    <div v-if="user.isConsultation===true">
                    <div class="box-shadow2 cardBorder">
                        <div class="row mt-3">
                            <div class="col-md-12 col-12"> 
                                <h4 class="text-20 font-weight-bold">Medical records - Updated</h4>
                            </div>
                            <div class="col-12 mt-3">
                                Further to the consultation on {{formatDate(user.consultationDate )}}, {{ user.doctorData.firstName }} {{user.doctorData.lastName}} has updated your medical record.
                                Click <span @click="goConsultation(user)"> <u class="linkToConsult" >here </u>to</span>  view your records.
                            </div>
                            <!-- <div class="col-12 mt-3">
                                Best wishes for your healthcare journey.
                            </div> -->
                            <div class="col-12 mt-3">
                                You own your medical records and control their access. You may also delete any of your records at any time.
                            </div>
                            <div class="col-12 mt-3">
                                We are forever committed to the confidentiality and privacy of your records. Best wishes for your healthcare journey. 
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-md-12 col-6">
                                <p class="text-start font-weight-bold">RxIx Admin</p>
                            </div>
                            <div class="col-md-12 col-6">
                                <p class="text-end" style="float:right"> {{ user.createdAt  }}
                                </p>
                            </div>
                        </div>
                        <nav class="nav nav-fill feed-icon">
                            <router-link to="" title="Send private" class="nav-link feed-icon-link">
                                <font-awesome-icon :icon="['fas', 'paper-plane']" />
                                <p class="feed-title d-none d-lg-block">Send message to Admin</p>
                            </router-link>
                        </nav>
                    </div>
                </div>
                </div>

                <!-- new family memeber is joined by the doctor-->
                <div v-for="doctorAddFamily in getDoctorAddedFamilyRecord" :key="doctorAddFamily">
                    <div class="box-shadow2 cardBorder">
                        <div class="row mt-3">
                            <div class="col-md-12 col-12">
                                <h4 class="text-20 font-weight-bold">Medical records - Updated</h4>
                            </div>
                            <div class="col-12 mt-3"> {{ doctorAddFamily.hcp_id_otp?.firstName }} has
                                added a new member to your list of family members.
                            </div>
                            <div class="col-12 mt-3">
                                Access to the new member’s medical records is through your registered mobile number/ mail
                                ID.
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-md-12 col-6">
                                <p class="text-start font-weight-bold">RxIx Admin</p>
                            </div>
                            <div class="col-md-12 col-6" >
                                <p class="text-end" style="float:right"> {{ doctorAddFamily.updateAt }}
                                </p>
                            </div>
                        </div>
                        <nav class="nav nav-fill feed-icon">
                            <router-link to="" title="Send private" class="nav-link feed-icon-link">
                                <font-awesome-icon :icon="['fas', 'paper-plane']" />
                                <p class="feed-title d-none d-lg-block">Send message to Admin</p>
                            </router-link>
                        </nav>
                    </div>
                </div>

                
                <div v-for="patientGroup in patientfilteredValue" :key="patientGroup">
                    <div class="box-shadow2 cardBorder">
                        <div class="row mt-3">
                            <div class="col-md-12 col-12">
                                <h4 class="text-20 font-weight-bold">New patient support group added to RxIx
                                </h4>
                            </div>
                            <div class="row box-items-space1">
                                <div class="col-opt-box1">
                                    <div class="col-fix mt-3 color-bg1">
                                        <div class="btn-text-bg1">
                                            <!-- <div class="box-text-bg1">{{ patientGroup.shortname }}</div> -->
                                            <div class="eyes-text1">
                                                <p>{{ patientGroup.groupname }}</p>
                                            </div>
                                        </div>
                                        <div class="text-center">
                                            <button class="btn btn-action-background1"
                                                @click="JoinPatientGroup(patientGroup)">Join
                                                group</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 mt-3">
                                Get answers and support to manage {{ patientGroup.groupname }}.
                            </div>
                        </div>

                        <div class="row mt-3">
                            <div class="col-md-12 col-6">
                                <p class="text-start font-weight-bold">RxIx Admin</p>
                            </div>
                            <div class="col-md-12 col-6">
                                <p class="text-end" style="float:right"> {{ patientGroup.createdAt }}
                                </p>
                            </div>
                        </div>
                        <nav class="nav nav-fill feed-icon">
                            <router-link to="" title="Send private" class="nav-link feed-icon-link">
                                <font-awesome-icon :icon="['fas', 'paper-plane']" />
                                <p class="feed-title d-none d-lg-block">Send message to Admin</p>
                            </router-link>
                        </nav>
                    </div>
                </div>

                <!-- show the msg for existing users -->
                <div v-for="doctorsData in doctorApprovedHistory" :key="doctorsData">
                    <div class="box-shadow2 cardBorder" >
                        <div class="row mt-3">
                            <div class="col-md-12 col-12">
                                <h4 class="text-20 font-weight-bold">
                                   New doctor has joined RxIx.
                                </h4>
                            </div>
                            <div class="col-12 mt-3">
                                We are happy to inform you that {{ doctorsData.approveDoctorHistory.firstName }} {{ doctorsData.approveDoctorHistory.lastName }}
                                has joined RxIx. If you wish to join the doctor’s community,
                                please click the button below.
                            </div>
                            <div class="row box-items-space2">
                                <div class="col-opt-box">
                                    <div class="col-fix mt-3 color">
                                        <div class="btn-text">
                                            <div class="box-text">{{ doctorsData.approveDoctorHistory.firstName}}</div>
                                            <div class="practiceText">{{ doctorsData.practiceAreaString }}</div>
                                        </div>
                                    </div>
                                    <div>
                                        <button class="btn btn-action-diary" :disabled="doctorycommunityDisable" @click="joinGroupData(doctorsData)">Join doctor's
                                            community</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row mt-3">
                            <div class="col-md-12 col-6">
                                <p class="text-start font-weight-bold">RxIx Admin</p>
                            </div>
                            <div class="col-md-12 col-6">
                                <p class="text-end" style="float:right"> {{ doctorsData.updateAt }}
                                </p>
                            </div>
                        </div>
                        <nav class="nav nav-fill feed-icon">
                            <router-link to="" title="Send private" class="nav-link feed-icon-link">
                                <font-awesome-icon :icon="['fas', 'paper-plane']" />
                                <p class="feed-title d-none d-lg-block">Send message to Admin</p>
                            </router-link>
                        </nav>
                    </div>
                </div>

                <!-- join doctor online community groups card-->

                <!-- join patient support groups card-->
                <div v-for="publicHistoty in publicFullhistory" :key="publicHistoty">
                <div v-if="publicHistoty.publicStatus === 'patient_group_created'">
                <div class="box-shadow2 cardBorder">
                <div class="row mt-3">
                <div class="col-md-12 col-12">
                <h4 class="text-20 font-weight-bold">Membership - Patients group</h4>
                </div>
                <div class="col-12 mt-3">
                You are now part of the {{ publicHistoty.publicGroupName.group_name }} patients group.
                Go ahead, interact and engage with the community.
                </div>
                </div>
                <div class="row mt-3">
                <div class="col-md-12 col-6">
                <p class="text-start font-weight-bold">RxIx Admin</p>
                </div>
                <div class="col-md-12 col-6">
                <p class="text-end" style="float:right"> {{ publicHistoty.createdAt }}
                </p>
                </div>
                </div>
                <nav class="nav nav-fill feed-icon">
                <router-link to="" title="Send private" class="nav-link feed-icon-link">
                <font-awesome-icon :icon="['fas', 'paper-plane']" />
                <p class="feed-title d-none d-lg-block">Send message to Admin</p>
                </router-link>
                </nav>
                </div>
                </div>
                <div v-if="publicHistoty.publicStatus === 'patient_group_deleted'">
                <div class="box-shadow1 cardBorder"  >
                <div class="row mt-3">
                <div class="col-md-12 col-12">
                <h4 class="text-20 font-weight-bold">Membership - Patients group </h4>
                </div>
                <div class="col-12 mt-3">
                Since you have removed {{ publicHistoty.publicGroupName.groupname }} from your
                patient groups, you are no more a member of the group. 
                </div>
                </div>
                <div class="row mt-3">
                <div class="col-md-12 col-6">
                <p class="text-start font-weight-bold">RxIx Admin</p>
                </div>
                <div class="col-md-12 col-6">
                <p class="text-end" style="float: right">
                {{ publicHistoty.createdAt }}
                </p>
                </div>
                </div>
                <nav class="nav nav-fill feed-icon">
                <router-link to="" title="Send private" class="nav-link feed-icon-link">
                <font-awesome-icon :icon="['fas', 'paper-plane']" />
                <p class="feed-title d-none d-lg-block">Send message to Admin</p>
                </router-link>
                </nav>
                </div>
                </div>
                <div v-if="publicHistoty.publicStatus === 'doctor_community_created'">
                <div class="box-shadow2 cardBorder">
                <div class="row mt-3">
                <div class="col-md-12 col-12">
                <h4 class="text-20 font-weight-bold">Membership - Doctor’s online community</h4>
                </div>
                <div class="col-12 mt-3">
                Welcome to the community of {{ publicHistoty.publicDoctorCommunity.firstName }} {{ publicHistoty.publicDoctorCommunity.lastName }}
                </div>
                <div class="col-12 mt-3">
                    As the doctor’s community member, 
                    any message of wisdom that the doctor chooses to publish with the community will reach you. 
                    Similarly, any post that you  publish will reach the doctor as well as his/ her other community members. 
                </div>
                <div class="col-12 mt-3">
                    Please note that this is a facility that allows you to 
                    engage with the doctor before you choose to consult him/ her.
                </div>
                <div class="col-12 mt-3">
                    If the doctor has chosen to receive private messages, 
                    you will be able to send a private message to him/ her.
                </div>
                </div>
                <div class="row mt-3">
                <div class="col-md-12 col-6">
                <p class="text-start font-weight-bold">RxIx Admin</p>
                </div>
                
                <div class="col-md-12 col-6">
                <p class="text-end" style="float:right"> {{ publicHistoty.createdAt }}
                </p>
                </div>
                </div>
                <nav class="nav nav-fill feed-icon">
                <router-link to="" title="Send private" class="nav-link feed-icon-link">
                <font-awesome-icon :icon="['fas', 'paper-plane']" />
                <p class="feed-title d-none d-lg-block">Send message to Admin</p>
                </router-link>
                </nav>
                </div>
                </div>
                <div v-if="publicHistoty.publicStatus === 'doctor_community_deleted'">
                <div class="box-shadow1 cardBorder">
                <div class="row mt-3">
                <div class="col-md-12 col-12">
                <h4 class="text-20 font-weight-bold">Membership - Doctor’s online community</h4>
                </div>
                <div class="col-12 mt-3">
                    You have chosen to remove yourself from the {{ publicHistoty.publicDoctorCommunity.firstName }} {{ publicHistoty.publicDoctorCommunity.lastName }}’s online community. Henceforth, 
                    you will not receive any communication from the doctor that is specific only to his/ her community.
                </div>
                </div>
                <div class="row mt-3">
                <div class="col-md-12 col-6">
                <p class="text-start font-weight-bold">RxIx Admin</p>
                </div>
                
                <div class="col-md-12 col-6">
                <p class="text-end" style="float:right"> {{ publicHistoty.createdAt }}
                </p>
                </div>
                </div>
                <nav class="nav nav-fill feed-icon">
                <router-link to="" title="Send private" class="nav-link feed-icon-link">
                <font-awesome-icon :icon="['fas', 'paper-plane']" />
                <p class="feed-title d-none d-lg-block">Send message to Admin</p>
                </router-link>
                </nav>
                </div>
                </div>
                </div>

                <!-- Welcome msg for all the cards -->
                <div v-if="option">
                    <div class="box-shadow2 cardBorder">
                        <div class="row mt-3">
                            <div class="col-md-12 col-12">
                                <h4 class="text-20 font-weight-bold">Welcome to RxIx!</h4>
                            </div>
                            <div class="col-12 mt-3">
                                It is our pleasure to welcome you to RxIx. 
Our initiative is to provide you with access to healthcare information you need + facilitate good patient care.
                            </div>
                            <div class="col-12 mt-3">
                                Help us by selecting the specialties that are of interest to you.
                            </div>
                            <div class="col-12 mt-3">
                                Based on your choice, we
                                <ul>
                                    <li>
                                        list topics aligned to the specialties as your topics of interest
                                    </li>
                                    <li>
                                        create your library of relevant resources
                                    </li>
                                    <li>
                                        enroll you into the related patient support groups
                                    </li>
                                </ul>
                            </div>
                            <div class="d-block d-lg-none">
                                <div class="row mt-3">
                                    <div class="text-btn">
                                        <button class="btn btn-blue-color text-white" @click="topicInterest()">
                                            Ok, let me select
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="d-none d-lg-block">
                                <div class="row mt-3">
                                    <div class="text-btn">
                                        <button class="btn btn-blue-color text-white" @click="goToTopicOfInterest()">
                                            Ok, let me select
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 mt-3">
                                Do take a quick tour of the app and get acquainted with the features and services we currently offer.
                            </div>
                            <div class="row mt-3">
                                <p class="text-start font-weight-bold">Best wishes!</p>
                                <div class="col-md-6 col-6">
                                    <p class="text-start font-weight-bold">RxIx Admin</p>
                                </div>
                                <div class="col-md-12 col-6">
                                    <p class="text-end" style="float:right"> {{ publicDataSignUp.createdAt }}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <nav class="nav nav-fill feed-icon">
                            <router-link to="" title="Send private" class="nav-link feed-icon-link">
                                <font-awesome-icon :icon="['fas', 'paper-plane']" />
                                <p class="feed-title d-none d-lg-block">Send message to Admin</p>
                            </router-link>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <userfooter></userfooter>
</template>
<script>
import userfooter from "../user/user-footer.vue"
import axios from "axios";
import moment from 'moment';
import { defineComponent } from "vue";
export default defineComponent({
  components: {
    userfooter
  },
    props: ["role", 'addPatient'],
    data() {
        return {
            doctorycommunityDisable:false,
            options: [],
            // Option: [],
            option: [],
            publicDataSignUp: {},
            doctorInformation: [],
            publicId: {},
            usersHistory: [],
            date: '',
            publicPatientGroups: [],
            adminPatientGroup: [],
            approvedDoctorData: [],
            //hideGroup: [],
            patientfilteredValue:[],
            patientfiltercheckValue:[],
            approveDoctor:[],
            doctorApprovedHistory:[],
            publicCreateDate:"",
            publicFullhistory:[],
            currentUserData:[],
            userConsutationRecord:[],
            fullUserFamilyConsultationRecord:[],
            consultationObj:{},
            getDoctorAddedFamilyRecord:[]
            // familyConsultationRecord:[]

        }
    },
    computed: {
        currentDateTime() {
            const date = new Date();
            const options = {
                month: '2-digit',
                day: '2-digit',
                year: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                hour12: true
            };
            return date.toLocaleString('en-US', options);
        },
    },
    created: async function () {
        let publicUserId = localStorage.getItem('publicSignUpData')
            this.publicId = JSON.parse(publicUserId);
        await  this.getPublic();
        await this.getUserHistory();
        this.getFamily();
        this.getPatientGroup()
        this.getDoctorCommunity()
        this.getMedicalRecords();
        this.date = this.printDate();

    },
    methods: {
    goToTopicOfInterest() {
      this.$router.push({ name: 'User-Topics' });
    },
    topicInterest(){
      this.$router.push({ name: 'User-Mobile-Topics' });
    },
      async getPublic() {
            const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
         await axios.get(`${BASE_API_URL}/public-user/${this.publicId}/one`)
                .then((response) => {
                    this.publicDataSignUp = response.data
                    this.publicDataSignUp.createdAt= moment(this.publicDataSignUp.createdAt).format("DD/MM/YYYY, h:mm a")
                    this.publicPatientGroups = this.publicDataSignUp.publicPatientId
                    this.doctorInformation = this.publicDataSignUp.doctorsID;
                })
        },
        formatDate(dateString) {
            const months = [
        "Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
      ];

      const date = new Date(dateString);
      const day = date.getDate().toString().padStart(2, '0');
      const month = months[date.getMonth()];
      const year = date.getFullYear();

      return `${day}-${month}-${year}`;
    },
       async getFamily(){
        const BASE_API_URL = process.env.VUE_APP_BASE_APIURL

       await axios
            .get(`${BASE_API_URL}/family-member`)
            .then((response) => {
                if (response?.data) {
                    response.data.map((getfamilyByuserID)=>{
                        getfamilyByuserID.updateAt= moment(getfamilyByuserID.updateAt).format("DD/MM/YYYY, h:mm a")
                        if((getfamilyByuserID?.users?.id === this.publicId)&& (getfamilyByuserID?.isDoctorAddUserFamily == true)){
                          this.getDoctorAddedFamilyRecord.push(getfamilyByuserID)
                        }
                    })
                }
            })
        },
        goConsultation(data){
            data.isUserLayout=true;
            data.userName=JSON.stringify(data.userName)
           const userData ={
                role:data.role,
                consultationDate:data.consultationDate,
                nextConsultationDate:data.nextConsultationDate,
                doctorName:data.doctorName,
                userFamData: data.userName,
                createdAt:data.newcreatedAt,
                isUserLayout:data.isUserLayout
            }
            this.$router.push({ path: '/user/records', query: userData })
        },
       async getMedicalRecords() {
            const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
          await  axios
                .get(`${BASE_API_URL}/upload-records`)
                .then((response) => {
                    if (response?.data) {
                        response.data.map((filterData)=>{
                            if(filterData?.user_id?.id === this.publicDataSignUp?.id){
                                filterData.newcreatedAt=filterData.createdAt
                                filterData.createdAt= moment(filterData.createdAt).format("DD/MM/YYYY, h:mm a")
                                this.userConsutationRecord.push(filterData)
                            }
                        })
                        this.userConsutationRecord.map((data)=>{
                            let consultationRecord;
                            consultationRecord={
                                userName:data.family_id || data.user_id,
                                role:data.family_id?.role || data.user_id?.role,
                                nextConsultationDate:data.nextConsultationDate,
                                consultationDate:data.consultation_date,
                                doctorName:data.doctor_name,
                                createdAt:data.createdAt,
                                newcreatedAt:data.newcreatedAt,
                                updateAt:data.updateAt,
                                doctorData:data.doctor_id,
                                isConsultation:data.isConsultation
                            }
                            this.fullUserFamilyConsultationRecord.push(consultationRecord)
                        })
                    }
                })
                .catch(function (error) {
                    console.log(error.response);
                });
        },
         getUserHistory() {
            const BASE_API_URL = process.env.VUE_APP_BASE_APIURL;
            this.publicFullhistory = []
          axios.get(`${BASE_API_URL}/public-history`)
            .then(async (response) => {
                response.data.map((date)=>{
      date.createdAt = moment(date.createdAt).format("DD/MM/YYYY, h:mm a")
    })
            this.patientGroupHistoryData=response.data
            const createdData = this.patientGroupHistoryData.filter(item => {
            return item.publicPatientGroupId?.id === this.publicId
            });
            this.currentUserData =createdData
            this.currentUserData.map((item)=>{
            let publicPatientGroupDetails;
                publicPatientGroupDetails = {
                createdAt:item.createdAt,
                publicStatus:item.public_status,
                publicGroupName:item.public_patientGroup,
                publicDoctorCommunity:item.public_doctor_community

                }
            this.publicFullhistory.push(publicPatientGroupDetails)
            })
    })
 .catch (function (error) {
      console.error("Error fetching data:", error);
    })
  },
    getDoctorCommunity() {
        const BASE_API_URL = process.env.VUE_APP_BASE_APIURL;

          axios.get(`${BASE_API_URL}/public-user/${this.publicId}/one`)
                .then((response) => {
                    // response.data.createdAt= moment(response.data.createdAt).format("DD/MM/YYYY, h:mm a")
                    this.publicCreateDate = response.data.createdAt
                    axios
            .get(`${BASE_API_URL}/taskManagerHistory`)
            
            .then((response)=>{
      
                this.approveDoctor = response.data;
                if(this.approveDoctor){
                    this.approveDoctor.map((docHistory)=>{
                        if(docHistory.verification?.status === 'Approved' && docHistory.updateAt > this.publicCreateDate && docHistory.users.mobile != '9182701237'){
                            let result;
                            result = {
                            approveDoctorHistory : JSON.parse(docHistory.action),
                            updateAt : docHistory.updateAt
                            }
                            result.updateAt = moment(result.updateAt).format("DD/MM/YYYY, h:mm a")
                            this.doctorApprovedHistory.push(result)
                             this.doctorApprovedHistory.map((doctor)=>{
                            const user = doctor.approveDoctorHistory
                            const practiceAreas = user.practiceId.map((item )=> item.practiceArea);
                            const truncatedPracticeAreas = practiceAreas.slice(0, 3);
                            doctor.practiceAreaString = truncatedPracticeAreas.join(", ");
                            if (practiceAreas.length > 3) {
                                doctor.practiceAreaString += " ...";
                            }
                            })
                        }
                    })
                }
            })
                })
       
        } ,
     async getPatientGroup() {
    const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
    await axios
        .get(`${BASE_API_URL}/public-user/${this.publicId}/one`)
        .then((response) => {
                this.DatePublic = response.data.createdAt;
        })
        await axios
        .get(`${BASE_API_URL}/patient-support/add`)
        .then((response) => {
        if (response?.data) {
            this.patientfiltercheckValue = response.data.filter(item => item.createdAt > this.DatePublic);
           this.patientfiltercheckValue.map((dataID) => {
              dataID.createdAt = moment(dataID.createdAt).format(
                "DD/MM/YYYY, h:mm a"
              );
            });
            this.patientfilteredValue = this.patientfiltercheckValue.filter((checkList) => checkList.status == true);
        }
            return response;
        })
    },

    JoinPatientGroup(patientGroup) {
        this.adminPatientGroup.push(patientGroup)
        const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
        this.adminPatientGroup = this.adminPatientGroup.concat(this.publicPatientGroups)
        let payloads = {
            publicPatientId: this.adminPatientGroup
        }
        axios
            .put(`${BASE_API_URL}/public-user/${this.publicId}/update`, payloads, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                if (response?.data) {
                    this.getUserHistory()
                }
            })
    },
    joinGroupData(DoctorData) {
        this.approvedDoctorData.push(DoctorData.approveDoctorHistory)
        const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
        this.approvedDoctorData = this.approvedDoctorData.concat(this.doctorInformation)
        let payload = {
            doctorsID: this.approvedDoctorData
        }
        axios
            .put(`${BASE_API_URL}/public-user/${this.publicId}/update`, payload, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                if (response?.data) {
                    this.getUserHistory()
                }
            })
    },
    printDate: function () {
        return new Date().toLocaleDateString('en-GB', {
            day: 'numeric',
            month: 'short',
            year: 'numeric'
        }).split(' ').join('-');
    },
},

})
</script>
<style>
.feed-icon-link {
  color: #cccccc !important;
  pointer-events: none;
}
.linkToConsult{
    cursor: pointer;
    color:#2774fd ;
}
.messageBox {
    padding: 1rem;
    margin: 1rem;
}



.cardBorder {
  padding: 15px;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  background-color: #fff;
  border-radius: 20px;
}

/* .wistomBorder {
    padding-left: 1rem;
    padding-right: 1rem;
    margin-left: 1rem;
    margin-right: 15rem;
} */

button.btn.btn-action-diary {
    background: #00979e;
    color: #fff;
    width: 100%;
    border-radius: inherit;
}

.btn-text {
    text-align: center;
    padding: 25px;
    margin: 10px;
    /* color: #00979e; */
}

.col-12.div-align {
    text-align: -webkit-center;
    width: 65%;
    margin: auto;
}



.eyes-text1 p {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 0px;
    min-height: 50px;
    display: flex;
    justify-content: center;
    align-items: center
}

.box-text-bg1 {
    font-size: 45px;
    color: #fff;
}


.col-opt-box1 {
    width: 100%;
    display: grid;
    justify-content: center;
}

.col-fix.mt-3.color-bg1 {
    border: 2px solid #00979e;
    width: 235px;
    /* margin-bottom: 30px; */
    background-color: #00979e;
}

button.btn.btn-action-background1 {
    background: #fff;
    color: #00979e;
    width: 100%;
    border-radius: inherit;
    border-radius: 2px solid #00979e;
}

.col-box {
    display: flex;
    justify-content: center;
    margin: 40px 40px 0px 40px;
    position: relative;
    bottom: 20px;
}

@media screen and (max-width:991px) {
    .col-box {
        display: flex;
        margin: auto;
    }
}

.btn-text-bg1 {
    text-align: center;
    padding: 15px;
    color: #fff;
    background-color: #00979e;
}

.btn.btn-action-background1 {
    color: #00979e;
}

.box-shadow1 {
    box-shadow: 0px 0px 0px 1px red, 0px 0px 0px 0px red !important;
}

.box-shadow2 {
    box-shadow: 0px 0px 0px 1px #00979e, 0px 0px 0px 0px #00979e !important;
}

@media screen and (max-width:991px) {
    .box-shadow {
        box-shadow: none;
    }

    .messageBox {
    padding: 0rem 0.5rem 0.5rem 0.5rem;
    margin: 0rem 0.5rem 0.5rem 0.5rem;
  }
}
.col-opt-box {
    width: 100%;
    display: grid;
    justify-content: center;
}

div.box-text {
    font-size: 22px;
    color: #00979e;
}
div.practiceText{
    font-size: 17px;
    color: #000;
    margin-bottom: 0px;
}
p.subpracticeareaText {
    font-size: 17px;
    margin-bottom: 0px;
}

.col-fix.mt-3.color {
    flex-direction: column;
    border: 2px solid #00979e;
    width: 242px;
    height: auto;
    min-height: 160px;
    display: flex;
    justify-content: center;
    background-color: #fff;
    overflow: hidden;
}


@media screen and (max-width:992px) {
    .btn-text {
        text-align: center;
        margin: 0px;
        padding: 2px;
    }
}

@media screen and (max-width:991px) {
    section.banner-two2.img.banner {
        margin-top: 0px;
    }

    .heading-community h1 {
        font-size: 33px;
    }

    .onlinecommunityText h3 {
        font-size: 22px;
    }

    .selectdoctor h2 {
        font-size: 28px;

    }

    .selectdoctor {
        margin-top: 2rem;
    }

}

@media screen and (max-width: 620px) {
 
    p.subpracticeareaText {
        font-size: 15px;
        margin-bottom: 0px;
    }
}

@media screen and (max-width:576px) {

    .heading-community h1 {
        font-size: 25px;
    }

    .onlinecommunityText h3 {
        font-size: 17px
    }

    .selectdoctor h2 {
        font-size: 21px;
    }

}

@media screen and (max-width: 560px) {

    .mobileNavbar-image {
        width: 35px;
        height: 35px;
    }

    p.mobileNavbarText {
        margin-left: auto;
        margin-right: auto;
        color: #707070;
        font-size: 13px;
        margin-top: 0px;
    }

    .mobileButton {
        height: 55px;
        width: 55px;
        font-size: 13px;
        padding-top: 18px;
    }

    section.banner-two2.img.banner {
        padding: 10px 0px 20px 0px;
    }

    .selectdoctor {
        margin-top: 1.5rem;
    }
}

@media screen and (max-width:470px) {
    p.subpracticeareaText {
        font-size: 12px;
        margin-bottom: 0px;
    }

    button.btn.btn-action-diary {
        font-size: 14px;
    }
    .btn-text {
        padding: 9px;
    }
}
</style>


